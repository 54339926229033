import styled from "styled-components";

export const Wrapper = styled.div`
  width: min(80rem, 85%);
    
  position: fixed;
  top: 0;
  z-index: 101;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 4rem 1rem 4rem;
  background: #fff;
`;

export const Logo = styled.img`
    width: 180px;
    cursor: pointer;
`;

import styled from "styled-components";
import {mediaQueries} from "../../constants/mediaQuery.config";

export const Container = styled.div`
  width: 100%;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  transition: all 0.3s ease;
`;

export const ChatWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 0px 0px 200px; 
  margin-top: 7rem;
`;

export const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  gap: 20px; 
  overflow-x: hidden;
  
  transition: all 0.3s ease;

  &::-webkit-scrollbar-track {
    background: white;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background: white;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 10px;
  }

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin: 0 auto;
  position: fixed;
  left: 0;
  bottom: 0;
  transition: all 1s ease;
  background-color: #ffffff;
  z-index: 10001;
`;

export const IntroMessage = styled.div`
  color: #0A0314;
  font-size: 24px;
  font-weight: 400;
  margin-left: 1.5rem;
  width: 45%;
  position: relative;

  //&::before {
  //  content: url(./bubble.svg);
  //  position: absolute;
  //  transform: translate(-87%, -30%);
  //  z-index: -1;
  //}
    
  ${mediaQueries.mobile} {
    width: 55%;
  }
`;

export const Bubble = styled.img`
    position: absolute;
    z-index: -1;
    transform: translate(-80%, -25%);
`;

export const InputWrapper = styled.div`
  background-color: #FFFFFF;
  width: 100%;
  max-width: 850px;
  padding: 0.75rem 0 1.25rem 0;
`;

export const DevModeContainer = styled.div`
  gap: 20px;
  padding: 20px;
  background-color: #ececec;
  margin-right: 10px;
  border: 1px solid #ececec;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
`;

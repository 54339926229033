import React from "react";
import {
  Title,
  Price,
  SummaryDesktop,
  SummaryMobile,
  Container,
  RightContainer,
  LeftContainer,
  Thumbnail,
  PriceWithoutVAT,
} from "./ResultCard.styled";

export interface ResultCardProps {
  summary: string;
  offerUrl: string;
  imageUrl: string;
  title: string;
  price?: string;
  price_without_vat?: string;
  key?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export const ResultCard: React.FC<ResultCardProps> = ({
  title,
  price,
  price_without_vat,
  imageUrl,
  summary,
  onClick,
}) => {
  return (
    <Container onClick={onClick}>
      <LeftContainer>
        <Thumbnail src={imageUrl} />
      </LeftContainer>
      <RightContainer>
        <Title>{title}</Title>
        <Price>
          {Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 0,
          }).format(Number(price ?? 0))}
        </Price>
        {price_without_vat && (
          <PriceWithoutVAT>
            Price without VAT:{" "}
            {Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
              minimumFractionDigits: 0,
            }).format(Number(price_without_vat ?? 0))}
          </PriceWithoutVAT>
        )}
        <SummaryDesktop>{summary}</SummaryDesktop>
      </RightContainer>
      <SummaryMobile>{summary}</SummaryMobile>
    </Container>
  );
};

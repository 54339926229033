import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Button = styled.button`
  display: flex;
  padding: 11px 40px;
  align-items: flex-start;
  border-radius: 6px;
  background: #f7b628;
  border: none;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

export const Title = styled.span`
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 75.878px;
  font-style: italic;
  font-weight: 700;
  line-height: 1;
`;

export const Tagline = styled.span`
  color: #fff;
  font-family: Poppins;
  font-size: 15px;
  font-style: italic;
  font-weight: 300;
  text-align: center;
  margin-right: 10px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-top: 20px;
`;

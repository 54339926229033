import React from "react";
import * as S from "./Input.styled";
import { SendButton } from "../SendButton";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  focusInput?: (input: HTMLInputElement) => void;
  onSend: () => void;
  isDisabled: boolean;
}

export const Input: React.FC<InputProps> = ({ focusInput, ...props }) => {
  return (
    <S.Wrapper disabled={props.disabled || false} >
      <S.Input
        {...props}
        id="chat-input"
        ref={(input: HTMLInputElement) => {
          if (input && focusInput) {
            focusInput(input);
          }
        }}
      />
      <SendButton onClick={props.onSend} isDisabled={props.isDisabled}/>
    </S.Wrapper>
  );
};

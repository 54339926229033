import React from "react";
import * as S from "./ChatHeader.styled";
import { Button, NewConversationIcon } from "..";
import styled from "styled-components";
import LogoImg from "./logo.png";
import { useAssistantChat } from "../../context";
import { useNavigate } from "react-router-dom";

const NewConversationButton = styled(Button)`
  background: #0d88d3;
  border: none;
`;

export const ChatHeader = () => {
  const navigate = useNavigate();
  const { createNewConversation, clearThread } = useAssistantChat();

  const handleNewConversation = async () => {
    console.log(`Creating new conversation from ChatHeader`);
    createNewConversation();
  };

  const handleLogoClick = async () => {
    await clearThread();
    navigate("/");
  };

  return (
    <S.Wrapper>
      <S.Logo src={LogoImg} alt="logo" onClick={handleLogoClick} />
      <NewConversationButton
        icon={<NewConversationIcon />}
        onClick={() => {
          handleNewConversation();
        }}
      />
    </S.Wrapper>
  );
};

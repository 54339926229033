export const HeroIcons = () => {
  return (
    <svg
      width="141"
      height="214"
      viewBox="0 0 141 214"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="63.0278"
        y="0.737793"
        width="70.951"
        height="53.1715"
        rx="25.2504"
        fill="#0d88d3"
      />
      <path
        d="M102.564 21.5348C102.564 20.6706 102.374 19.9462 101.992 19.3616C101.611 18.7769 101.065 18.3321 100.353 18.0271C99.6666 17.6967 98.8405 17.5314 97.8746 17.5314C96.68 17.5314 95.5743 17.8238 94.5575 18.4084C93.5408 18.9676 92.6893 19.7683 92.003 20.8104L88.5334 18.1034C89.3468 17.0866 90.211 16.2224 91.126 15.5107C92.0411 14.799 93.0833 14.2525 94.2525 13.8712C95.4218 13.4899 96.7562 13.2993 98.2559 13.2993C99.476 13.2993 100.633 13.4899 101.726 13.8712C102.844 14.2525 103.835 14.8117 104.699 15.5488C105.589 16.2605 106.275 17.1248 106.758 18.1415C107.267 19.1328 107.521 20.2639 107.521 21.5348C107.521 23.0599 107.127 24.4071 106.339 25.5764C105.576 26.7202 104.572 27.648 103.327 28.3597C102.081 29.046 100.772 29.4781 99.3997 29.656L99.171 33.3163H95.2438L94.4813 26.5296H95.6632C97.1883 26.5296 98.4593 26.2754 99.476 25.767C100.518 25.2332 101.293 24.585 101.802 23.8225C102.31 23.0345 102.564 22.272 102.564 21.5348ZM94.1381 38.5779C94.1381 37.8153 94.4304 37.1544 95.0151 36.5952C95.6251 36.036 96.3749 35.7564 97.2646 35.7564C98.1797 35.7564 98.9295 36.036 99.5141 36.5952C100.099 37.1544 100.391 37.8153 100.391 38.5779C100.391 39.3404 100.099 40.0013 99.5141 40.5605C98.9295 41.1197 98.1797 41.3993 97.2646 41.3993C96.3749 41.3993 95.6251 41.1197 95.0151 40.5605C94.4304 40.0013 94.1381 39.3404 94.1381 38.5779Z"
        fill="white"
      />
      <path
        d="M132.046 52.1422C132.329 52.9103 131.576 53.654 130.811 53.3619L111.642 46.0376C110.995 45.7901 110.82 44.9562 111.313 44.469L123.394 32.5356C123.887 32.0484 124.719 32.2339 124.958 32.8845L132.046 52.1422Z"
        fill="#0d88d3"
      />
      <rect
        x="17.2444"
        y="72.7002"
        width="70.951"
        height="53.1715"
        rx="25.2504"
        fill="#fee011"
      />
      <path
        d="M49.5148 85.8717H55.6152L54.5095 104.135H50.6205L49.5148 85.8717ZM49.4385 110.464C49.4385 109.625 49.7308 108.939 50.3154 108.405C50.9255 107.846 51.6753 107.566 52.565 107.566C53.48 107.566 54.2299 107.846 54.8145 108.405C55.3991 108.939 55.6914 109.625 55.6914 110.464C55.6914 111.303 55.3991 112.002 54.8145 112.561C54.2299 113.095 53.48 113.362 52.565 113.362C51.6753 113.362 50.9255 113.095 50.3154 112.561C49.7308 112.002 49.4385 111.303 49.4385 110.464Z"
        fill="white"
      />
      <path
        d="M17.74 127.383C16.9755 127.675 16.2226 126.931 16.5053 126.163L23.594 106.906C23.8334 106.255 24.6652 106.07 25.1584 106.557L37.239 118.491C37.7322 118.978 37.5569 119.812 36.9093 120.059L17.74 127.383Z"
        fill="#fee011"
      />
    </svg>
  );
};

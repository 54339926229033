import React from "react";
import * as S from "./LandingHeader.styled";
import LogoImg from "./logo.png";
import { AssistantToggle } from "../AssistantToggle";

export const LandingHeader = () => {
  return (
    <S.Wrapper>
      <S.Logo src={LogoImg} alt="logo" />
      <AssistantToggle />
    </S.Wrapper>
  );
};

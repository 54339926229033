import React, { useEffect } from "react";
import * as S from "./Home.styled";
import { Input } from "../../components";
import { LandingHeader } from "../../components/LandingHeader";
import { LandingHero } from "../../components/LandingHero";
import { CookieNotice } from "../../components";
import { useNavigate } from "react-router-dom";
import { useAssistantChat } from "../../context";
import { LandingFooter } from "../../components/LandingFooter";
import TryItOut from "./try-it-out.svg";

export const Home = () => {
  const suggestions = [
    "What is a smart sensor?",
    "How to set up a smart sensor?",
    "What does the red light on the smart sensor mean?"
  ];

  const navigate = useNavigate();
  const { thread, createNewConversation } = useAssistantChat();
  const [inputValue, setInputValue] = React.useState("");
  const [showCookieNotice, setShowCookieNotice] = React.useState(false);

  const handleSuggestionsClick = async (suggestion: string) => {
    if (thread === "" || thread === undefined) {
      await createNewConversation();
    }
    navigate("/chat", { replace: true, state: { message: suggestion } });
  };

  const handleSendMessage = async () => {
    if (thread === "" || thread === undefined) {
      await createNewConversation();
    }
    setInputValue("");
    navigate("/chat", { replace: true, state: { message: inputValue } });
  };

  useEffect(() => {
    if (thread) {
      navigate("/chat", { replace: true });
    }
  }, [thread]);

  useEffect(() => {
    if (document.cookie.includes("cookieCons=true")) {
      setShowCookieNotice(false);
    } else {
      setShowCookieNotice(true);
    }
  }, []);

  const acceptCookies = () => {
    const date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    document.cookie = `cookieCons = true; expires = ${date.toUTCString()}; path=/`;
    setShowCookieNotice(false);
  };

  return (
    <S.Home>
      <LandingHeader />
      <S.PageContent>
        <LandingHero />
        <S.LandingHeading>
        </S.LandingHeading>
        <S.LandingDescriptionContainer>
          <S.LandingDescriptionTitleContainer>
            <h2>Say goodbye to tedious documentation search!</h2>
          </S.LandingDescriptionTitleContainer>
          <S.LandingDescription>
            FritzGPT: Your Ultimate Time-Saving Solution for Efficiently
            Navigating and Understanding Complex Documentation
          </S.LandingDescription>
        </S.LandingDescriptionContainer>

        <S.InputWrapper>
          <S.InputTitleLabel>
            <img src={TryItOut} alt={"Try it out"} />
          </S.InputTitleLabel>
          <Input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Start conversation..."
            onKeyDown={(e) =>
              e.key === "Enter" && inputValue && handleSendMessage()
            }
            isDisabled={inputValue.length === 0}
            onSend={handleSendMessage}
            focusInput={(input) => input && input.focus()}
          />
        </S.InputWrapper>
        <S.SuggestionsContainer>
          <label>Try searching</label>
          {suggestions.map((suggestion, index) => (
            <S.Suggestion
              key={index}
              onClick={() => handleSuggestionsClick(suggestion)}
            >
              {suggestion}
            </S.Suggestion>
          ))}
        </S.SuggestionsContainer>
        <LandingFooter />
      </S.PageContent>
      {showCookieNotice && <CookieNotice onClick={acceptCookies} />}
    </S.Home>
  );
};

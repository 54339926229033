import { HeroIcons } from "./HeroIcons";
import { HeroImg } from "./HeroImg";
import * as S from "./LandingHero.styled";

export const LandingHero = () => {
  const isMobile = window.innerWidth < 768;

  return (
    <>
      <S.ContainerMobile>
        <S.ImageWrapper>
          <HeroImg />
        </S.ImageWrapper>
        <S.IconWrapper>
          <HeroIcons />
        </S.IconWrapper>
        <S.HeroTitle>
          <span>{"Your AI Powered"}</span>
          <span>{"Sauter"}</span>
          <span>{"Assistant"}</span>
        </S.HeroTitle>
      </S.ContainerMobile>
      <S.ContainerDesktop>
        <HeroImg />
      </S.ContainerDesktop>
    </>
  );
};

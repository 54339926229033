import styled from "styled-components";
import {mediaQueries} from "../../constants/mediaQuery.config";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #f5f5f5;
  border-bottom: 21px solid #D7E1EF;
`;

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 0 50px;
  `

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 100px;
`;

export const Title = styled.span`
  color: #1f2b59;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
`;

export const StepsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin-top: 50px;
  margin-bottom: 100px;

  ${mediaQueries.mobile} {
    flex-direction: column;
    align-items: center;
  }
`;
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width:500px;
  gap: 5px;
  color: #1f2b59;
  text-align: left;
`;

export const Number = styled.span`
  font-family: Poppins;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const Title = styled.span`
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const Text = styled.span`
font-family: Poppins;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
`;





import styled from "styled-components";

export const SendButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  background: transparent;
  margin-right: 20px;
`;

import styled from "styled-components";
import {mediaQueries} from "../../constants/mediaQuery.config";

export const Container = styled.div`
   position: relative;
`;

export const CarouselContent = styled.div`
    display: flex;
    position: relative;
    overflow-x: auto;
    gap: 0.5rem;
    padding: 0.5rem 2rem;
    cursor: grab;
    
    &::-webkit-scrollbar {
        display: none;
    }

`;

export const RightShadow = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 2rem;
    height: 100%;
    background: linear-gradient(to left, rgb(255, 255, 255), rgba(255, 255, 255, 0));
    z-index: 100;

    ${mediaQueries.tablet} {
        width: 1rem;
    }
`;

export const LeftShadow = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 2rem;
    height: 100%;
    background: linear-gradient(to right, rgb(255, 255, 255), rgba(255, 255, 255, 0));
    z-index: 100;

    ${mediaQueries.tablet} {
        width: 1rem;
    }
`;


export const RightArrow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 30%;
    right: 0;
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
    z-index: 1;
    margin-right: 0.5rem;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);

    &:hover {
        transition: all 0.1s ease-in-out;
        scale: 1.02;
    }

    ${mediaQueries.tablet} {
        width: 3rem;
        height: 3rem;
    }
`;

export const LeftArrow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 30%;
    left: 0;
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
    z-index: 1;
    margin-left: 0.5rem;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);

    &:hover {
        transition: all 0.1s ease-in-out;
        scale: 1.02;
    }

    ${mediaQueries.tablet} {
        width: 3rem;
        height: 3rem;
    }
`;


export const ArrowIcon = styled.img`
    width: 1.6rem;
    height: 1.6rem;
    object-fit: contain;

    ${mediaQueries.tablet} {
        width: 1.4rem;
        height: 1.4rem;
    }
`;

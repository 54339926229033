import React, {FC} from "react";
import * as S from "./Chat.styled";
import { Chat as ChatComponent } from "../../components";

export interface IChatProps {
    scrollToBottom: (index: number | string) => void;
}

export const Chat: FC<IChatProps>  = ({scrollToBottom}) => {
    return (
    <S.Chat>
      <ChatComponent scrollToBottom={scrollToBottom}/>
    </S.Chat>
  );
};

import React from "react";
import * as S from "./ToggleSwitch.styled";

export interface ToggleSwitchProps {
  firstItem: string;
  secondItem: string;
  currentItem: string;
  onChange: (item: string) => void;
  className?: string;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  firstItem,
  secondItem,
  currentItem,
  onChange,
  className,
}) => {
  return (
    <S.ToggleSwitchWrapper className={className}>
      <S.ToggleSwitchItem
        onClick={() => onChange(firstItem)}
        isActive={currentItem === firstItem}
      >
        {firstItem}
      </S.ToggleSwitchItem>
      <S.ToggleSwitchAI
        onClick={() => onChange(secondItem)}
        isActive={currentItem === secondItem}
      >
        {secondItem}
      </S.ToggleSwitchAI>
    </S.ToggleSwitchWrapper>
  );
};

import React from "react";
import * as S from "./Message.styled";
import Markdown from "react-markdown";
import { Annotation } from "../../types";

interface MessageProps {
  content?: string;
  position: "left" | "right";
  id: string;
  withoutMarginBottom?: boolean;
  annotations?: Annotation[];
}

const BACKEND_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_BACKEND_URL_PROD
    : process.env.REACT_APP_BACKEND_URL_LOCAL;

export const Message: React.FC<MessageProps> = ({
  content,
  position,
  withoutMarginBottom,
  id,
  annotations,
}) => {
  const fileNameSet = new Set<string>();

  return (
    <S.Wrapper
      position={position}
      id={id}
      withoutMarginBottom={withoutMarginBottom}
    >
      <S.ContentWrapper position={position}>
        <Markdown>{content}</Markdown>
        {annotations && annotations.length > 0 && (
          <S.Source>
            Sources:&nbsp;
            {annotations
              .filter((annotation) => {
                const filename = annotation.filename;
                const isDuplicate = fileNameSet.has(filename);
                fileNameSet.add(filename);
                return !isDuplicate;
              })
              .map((annotation: Annotation) => (
                <a
                  key={annotation.file_citation.file_id}
                  href={`${BACKEND_URL}/download/${annotation.filename}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {`[${annotation.filename}]`}
                </a>
              ))}
          </S.Source>
        )}
      </S.ContentWrapper>
    </S.Wrapper>
  );
};

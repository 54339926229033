import React from "react";
import * as S from "./AssistantToggle.styled";
import { useAssistantChat } from "../../context/MessagesContext";

export interface AssistantToggleProps {
  className?: string;
}

export const AssistantToggle = ({ className }: AssistantToggleProps) => {
  const { assistantType, onAssistantTypeChange } = useAssistantChat();

  return (
    <S.Wrapper className={className}>
      <S.Option
        active={assistantType === "information"}
        onClick={() => onAssistantTypeChange("information")}
      >
        Information Assistant
      </S.Option>
      <S.Option
        active={assistantType === "marketing"}
        onClick={() => onAssistantTypeChange("marketing")}
      >
        Marketing Assistant
      </S.Option>
    </S.Wrapper>
  );
};

import React from "react";
import { ResultCard, ResultCardProps } from "../ResultCard";
import { Container, Wrapper } from "./ResultSection.styled";

interface ResultSectionProps {
  offers: ResultCardProps[];
}

export const ResultSection: React.FC<ResultSectionProps> = ({ offers }) => {
  const [cursorOnMouseDown, setCursorOnMouseDown] = React.useState({
    x: 0,
    y: 0,
  });

  const handleOnMouseDown = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setCursorOnMouseDown({
      x: e.clientX,
      y: e.clientY,
    });
    e.preventDefault(); // stops weird link dragging effect
  };

  const handleOnClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    url: string
  ) => {
    const cursorOnMouseUp = {
      x: e.clientX,
      y: e.clientY,
    };
    if (
      cursorOnMouseDown.x !== cursorOnMouseUp.x ||
      cursorOnMouseDown.y !== cursorOnMouseUp.y
    ) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      window.open(url, "_blank");
    }
  };

  return (
    <Wrapper onMouseDown={handleOnMouseDown}>
      <Container>
        {offers.map((offer, index) => (
          <ResultCard
            key={`${offer.title}-${index}`}
            {...offer}
            onClick={(e) => {
              return handleOnClick(e, offer.offerUrl);
            }}
          />
        ))}
      </Container>
    </Wrapper>
  );
};

import * as S from "./LandingHero.styled";
import HeroIconsSvg from "./hero-icons.svg";
import {DesktopHeroIconsWrapper} from "./LandingHero.styled";

export const HeroImg = () => {
    return (
        <S.VideoContainer>
            <S.VideoWrapper style={{
                clipPath: "polygon( 47.565% 51.668%,47.565% 51.668%,43.638% 53.154%,40.336% 54.82%,37.536% 56.547%,35.113% 58.215%,32.943% 59.705%,30.902% 60.898%,28.866% 61.674%,26.711% 61.914%,24.313% 61.499%,21.547% 60.309%,21.547% 60.309%,16.925% 58.245%,13.135% 57.415%,10.08% 57.635%,7.66% 58.724%,5.776% 60.497%,4.331% 62.772%,3.224% 65.366%,2.358% 68.097%,1.634% 70.78%,0.952% 73.234%,0.952% 73.234%,0.295% 75.883%,0.034% 78.178%,0.139% 80.185%,0.582% 81.972%,1.331% 83.605%,2.356% 85.151%,3.629% 86.678%,5.118% 88.252%,6.794% 89.94%,8.626% 91.808%,8.626% 91.808%,10.512% 93.428%,12.379% 94.387%,14.248% 94.782%,16.138% 94.707%,18.07% 94.259%,20.063% 93.532%,22.137% 92.622%,24.312% 91.623%,26.608% 90.633%,29.044% 89.745%,29.044% 89.745%,31.453% 89.131%,33.678% 88.873%,35.76% 88.929%,37.74% 89.259%,39.658% 89.822%,41.555% 90.577%,43.472% 91.482%,45.448% 92.498%,47.526% 93.584%,49.744% 94.697%,49.744% 94.697%,51.933% 95.467%,53.924% 95.629%,55.761% 95.305%,57.486% 94.618%,59.143% 93.691%,60.775% 92.647%,62.424% 91.607%,64.134% 90.695%,65.948% 90.034%,67.909% 89.745%,67.909% 89.745%,69.947% 90.047%,71.954% 90.961%,73.91% 92.313%,75.799% 93.931%,77.603% 95.638%,79.303% 97.263%,80.882% 98.63%,82.323% 99.567%,83.607% 99.898%,84.717% 99.451%,84.717% 99.451%,87.026% 96.943%,88.662% 94.222%,89.767% 91.394%,90.482% 88.564%,90.947% 85.836%,91.303% 83.317%,91.691% 81.112%,92.252% 79.325%,93.128% 78.062%,94.457% 77.428%,94.457% 77.428%,95.932% 76.853%,97.157% 75.714%,98.145% 74.041%,98.907% 71.865%,99.456% 69.213%,99.806% 66.117%,99.967% 62.606%,99.953% 58.709%,99.776% 54.457%,99.449% 49.878%,99.449% 49.878%,98.96% 44.454%,98.256% 37.948%,97.283% 30.811%,95.99% 23.497%,94.322% 16.456%,92.228% 10.141%,89.655% 5.003%,86.549% 1.496%,82.86% 0.071%,78.532% 1.179%,78.532% 1.179%,73.417% 5%,69.536% 9.937%,66.594% 15.675%,64.297% 21.896%,62.35% 28.284%,60.459% 34.524%,58.33% 40.298%,55.667% 45.291%,52.177% 49.187%,47.565% 51.668% )",
            }}>
                <S.ImageOverlay />
                <video
                    style={{
                        width: '100%',
                        height: '100%'
                }}
                    autoPlay
                    loop
                    muted
                    playsInline
                >
                    <source src="./landing-video.mp4" type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
            </S.VideoWrapper>
            <DesktopHeroIconsWrapper>
                <img src={HeroIconsSvg} alt="hero-icons" />
            </DesktopHeroIconsWrapper>
        </S.VideoContainer>
    );
};

import React from "react";
import * as S from "./LoadingDots.styled";

export const LoadingDots = () => {
  return (
    <S.Wrapper>
      <S.Loader>
        <div></div>
      </S.Loader>
    </S.Wrapper>
  );
};

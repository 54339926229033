import styled, { css } from "styled-components";
import { ToggleSwitch as ToggleSwitchComponent } from "../ToggleSwitch/ToggleSwitch";

type HeroSearchWrapperProps = {
  isFullScreen?: boolean;
};

const getSizeStyles = (isFullScreen?: boolean) => {
  if (isFullScreen) {
    return css`
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      position: fixed;
      min-height: 100%;
      min-width: 100%;
    `;
  }

  return css`
    position: absolute;
    top: 530px;
    left: 130px;
  `;
};

export const HeroSearchWrapper = styled.div<HeroSearchWrapperProps>(
  ({ isFullScreen }) => css`
    display: flex;
    width: fit-content;
    min-width: 434px;
    z-index: 9;
    display: block;
    ${getSizeStyles(isFullScreen)}
  `
);

export const HeroSearch = styled.div`
  position: relative;
  z-index: 2;
  margin: 0px;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 24px 12px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ToggleSwitch = styled(ToggleSwitchComponent)`
  width: 390px;
  margin: 30px 15px 5px 15px;
  align-self: center;
`;

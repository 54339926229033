import React from "react";
import "./App.css";
import { Chat, Home } from "./templates";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import styled from "styled-components";
import { AssistantChatProvider } from "./context";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: min(65rem, 100%);
  overflow: visible;
  overflow-x: hidden;
    
    &::-webkit-scrollbar {
        width: 0;
    }
`;

const ScrollContainer = styled.div`
    height: 100dvh;
    overflow-y: scroll;
    
`

const HomeRoute = () => {
    return (
        <PageContainer>
            <Home />
        </PageContainer>
    );
}

const ChatRoute = () => {
  const containerRef = React.useRef<HTMLDivElement>(null);

  const scrollToBottom = (messageIndex: number | string) => {
      const scrollMessageRef = document.getElementById(`message-right-${messageIndex}`);
      if(scrollMessageRef){
        const offsetTop = scrollMessageRef.offsetTop;
        const viewHeight = scrollMessageRef.clientHeight + 50; // gap between messages
        containerRef.current?.scroll({
            top: offsetTop - viewHeight,
            behavior: "smooth",
        });
      }
  };

    return (
        <ScrollContainer ref={containerRef}>
            <PageContainer>
                <Chat scrollToBottom={scrollToBottom}/>
            </PageContainer>
        </ScrollContainer>
    );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeRoute />,
  },
  {
    path: "/chat",
    element: <ChatRoute />,
  },
]);

function App() {
  return (
    <>
      <AssistantChatProvider>
        <RouterProvider router={router} />
      </AssistantChatProvider>
    </>
  );
  
}

export default App;

import styled from "styled-components";

export const Button = styled.button`
  gap: 8px;
  display: flex;
  padding: 8px;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: #fff;
  border: none;
  border: 0.7px solid #214168;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  filter: drop-shadow(0px 0px 0.8px rgba(0, 0, 0, 0.15));
`;

export const Text = styled.span`
  color: #214168;
  font-size: 13.117px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: Poppins;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
import styled from "styled-components";
import { mediaQueries } from "../../constants/mediaQuery.config";

export const Wrapper = styled.div`
  margin: 0 10px;
`;

export const Container = styled.div`
  margin: 0 15px;
  position: relative;
  gap: 1rem;
  display: flex;
  flex-direction: column;

  ${mediaQueries.mobile} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &::before {
    content: "";
    position: absolute;
    width: 4px;
    height: 100%;
    border-radius: 4px;
    top: 0px;
    background-color: #fee011;
    left: -10px;
  }
`;

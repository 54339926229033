import React from "react";
import * as S from "./SendButton.styled";
import { ArrowIcon } from "../ArrowIcon";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isDisabled: boolean;
}

export const SendButton = ({ isDisabled, ...props }: ButtonProps) => {
  return (
    <S.SendButton {...props} disabled={isDisabled}>
      <ArrowIcon isDisabled={isDisabled}/>
    </S.SendButton>
  );
};

import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  background-color: #0d88d3;
  border-radius: 10px;
  cursor: pointer;
`;

export const Option = styled.div<{ active: boolean }>(
  ({ active }) => css`
    background-color: ${active ? "#fff" : "#0d88d3"};
    color: ${active ? "#0d88d3" : "#fff"};
    border-radius: 10px;
    padding: 5px 20px;
    border: 5px solid #0d88d3;
  `
);

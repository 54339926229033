import styled from "styled-components";

export const Heading = styled.h2`
  color: #363636;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 23px;
`;

export const Text = styled.p`
  color: #363636;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

export const Wrapper = styled.div`
  opacity: 0;
  animation: appear 0.5s ease-in-out;
  animation-fill-mode: forwards;
  padding: 50px 0;

  @keyframes appear {
    0% {
      opacity: 0;
      transform: translateX(-5%);
    }

    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  margin: 0 auto;
  width: min(70rem, 95%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1.6rem 0;
  color: #838383;
  gap: 1rem;
  font-size: 0.75rem;

  span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  p {

    margin: 0;
  }

  a {
    color: #838383;
    margin: 0;
    text-decoration: underline;
  }

  @media (min-width: 600px) {
    z-index: -1;
    font-size: 0%.8;
    flex-direction: row;
    flex-flow: row-reverse;
    padding: 1.6rem 0;
    justify-content: end;
    color: white;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0 2rem 2rem 0;

    path {
        fill: white;
    }

    span {
      align-items: end;
    }

    a {
      color: white;
    }
  }
`;

import styled from "styled-components";

export const ToggleSwitchWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 35px;
  padding: 4px 6px;
  align-items: flex-start;
  gap: 11px;
  flex-shrink: 0;
  background-color: #ececec;
  border-radius: 35px;
`;

export const ToggleSwitchItem = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  align-self: stretch;
  background-color: ${({ isActive }) => (isActive ? "#fff" : "transparent")};
  border-radius: 35px;
  color: #323330;
  text-align: center;
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
`;

export const ToggleSwitchAI = styled(ToggleSwitchItem)`
  color: #ac009b;
`;

import styled, { css } from "styled-components";

export const Input = styled.input`
  padding: 17px 22px 16px 22px;
  align-items: center;
  flex: 1 0 0;
  border: none;
  width: 100%;
  font-size: 18px;

  &:focus {
    outline: none;
  }
`;

export const Wrapper = styled.div<{disabled: boolean}>(
  ({ disabled }) => css`
  border-radius: 74px;
  border: 1px solid #818181;
  background: ${disabled ? "#efefef" : "#fff"};
  box-shadow: 0px 0px 9.9px 0px rgba(0, 0, 0, 0.03);
  display: flex;
  align-items: center;
  width: 95%;
  margin: 0 auto;
  overflow: hidden;
`);

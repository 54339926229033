import styled, { css } from "styled-components";
import { mediaQueries } from "../../constants/mediaQuery.config";

export const Container = styled.div`
  background: linear-gradient(
    87.78deg,
    rgba(179, 220, 88, 0.1) 1.99%,
    rgba(137, 199, 0, 0.1) 87.21%
  );
  padding: 1rem;
  gap: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${mediaQueries.mobile} {
    width: auto;
  }

  ${mediaQueries.fromTablet} {
    gap: 2rem;
    width: 80%;
    flex-wrap: nowrap;
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.03);
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaQueries.mobile} {
    justify-content: center;
  }
`;

export const LeftContainer = styled.div`
  min-width: 140px;
  width: 140px;
  height: 90px;

  ${mediaQueries.fromTablet} {
    min-width: 180px;
    width: 180px;
    height: 123px;
  }
`;

type ThumbnailProps = {
  src: string;
};

export const Thumbnail = styled.div<ThumbnailProps>(
  ({ src }) => css`
    width: 100%;
    height: -webkit-fill-available;
    background-image: url(${src});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 2px;
  `
);

export const Title = styled.span`
  color: #1f2b59;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;

  ${mediaQueries.mobile} {
    font-size: 14px;
  }
`;

export const Price = styled.span`
  color: rgba(31, 43, 89, 0.8);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  ${mediaQueries.mobile} {
    font-size: 12px;
  }
`;

export const PriceWithoutVAT = styled(Price)`
  font-size: 12px;
`;

export const SummaryDesktop = styled.p`
  font-family: Helvetica Neue;
  font-size: 14px;
  display: flex;

  ${mediaQueries.tablet} {
    display: none;
  }
`;

export const SummaryMobile = styled(SummaryDesktop)`
  display: flex;
  flex-basis: 100%;
  ${mediaQueries.fromTablet} {
    display: none;
  }
`;

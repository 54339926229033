import styled, { css } from "styled-components";
import {mediaQueries} from "../../constants/mediaQuery.config";

export const Card = styled.div` 
    width: 20rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.03);
    background-color: #fff;
    border-radius: 9px;
    padding: 1rem;

    &:hover {
        transform: scale(1.01);
        transition: transform 0.25s ease-in-out;
    }

    ${mediaQueries.mobile} {
        width: 16rem;
        height: 16rem;
    }
`;

type ThumbnailProps = {
  src: string;
};

export const Thumbnail = styled.div<ThumbnailProps>(
  ({ src }) => css`
    width: 100%;
    height: 180px;
    border-radius: 9px 9px 0px 0px;
    background-image: url(${src});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    ${mediaQueries.mobile} {
      height: 10rem;
    }
  `
);

export const Content = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  margin-left: 20px;
`;

export const Title = styled.span`
  color: #1f2b59;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;

    ${mediaQueries.mobile} {
    font-size: 14px;
  }
`;

export const Price = styled.span`
  color: rgba(31, 43, 89, 0.8);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

    ${mediaQueries.mobile} {
    font-size: 12px;
  }
`;

export const PriceWithoutVAT = styled(Price)`
  font-size: 12px;
`

import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  width: 15px;
  height: 8px;
  padding: 4px 30px;
  /* animated appear */
  animation: appear 0.2s ease-in-out;
  animation-delay: 0.2s;
  @keyframes appear {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const Loader = styled.div`
  display: flex;
  position: relative;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
  ::before,
  ::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  ::before {
    left: -15px;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  ::after {
    left: 15px;
    width: 6px;
    height: 6px;
    border-radius: 5px;
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dot-flashing {
    0% {
      background-color: #ffffff;
      width: 8px;
      height: 8px;
    }
    50%,
    100% {
      background-color: #939393;
      width: 6px;
      height: 6px;
    }
  }
`;
